@keyframes jump {
  0% {
    transform: translate(0%, 0%) scale(1.25, 0.75);
  }
  50% {
    transform: translate(0%, -50%) scale(1, 1);
  }
  100% {
    transform: translate(0%, 0%) scale(1.25, 0.75);
  }
}

@mixin store_button($bg-color, $text-color) {
  border: none;
  background: $bg-color;
  color: $text-color;
  border-radius: 4px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  &:hover {
    background: darken($bg-color, 5%);
  }

  &:disabled {
    background: #babec4;
  }
}

.store {
  height: 100vh;
}

.store__error {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  text-align: center;
}

.store__primary_button {
  @include store_button(#00d1c5, white);
}

.store__danger_button {
  @include store_button(#e07748, white);
}

.header {
  display: flex;
  align-items: center;
  height: 5rem;
  padding-left: 2rem;
  width: 100%;
}

.header__logo {
  img {
    height: 3rem;
  }
}

.header__navigation {
  margin-left: auto;
  margin-right: 2rem;
}

.header__locales {
  margin-right: 0.5rem;

  .dropdown-menu {
    min-width: unset;
  }
}

.locale {
  color: black;
  margin: 0.1rem;
  padding: 0.5rem 1em 0.5rem 1rem;
  cursor: default;
  border-radius: 1rem;

  &:hover {
    background: darken(rgb(241, 244, 248), 3%);
    text-decoration: none;
  }
}

.navigation__cart_button {
  cursor: pointer;
  animation: jump 0.6s;
  z-index: 10;

  &--inactive {
    animation: unset;
    color: #c4c2c2;
    cursor: default;
  }
}

.cart {
  position: absolute;
  background: #eff1f4;
  right: 0;
  border-radius: 20px;
  box-shadow: 0px 25px 40px #1687d933;
  width: 20rem;
  height: 25rem;
  cursor: default;
  padding: 1.5rem;
  display: flex;
  flex-flow: column;
  z-index: 1;
}

.cart__items_container {
  flex: 4;
  width: 100%;
  overflow: scroll;
}

.cart_item {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.cart__actions_container {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.cart_item__price {
  font-weight: bold;
}

.store__products {
  background-color: #f5f6f8;
  padding: 2rem;
  display: flex;
  flex-flow: row;
  overflow: scroll;
  height: calc(100vh - 5rem);
  max-height: 90vh;
}

.store__product_list {
  display: flex;
  flex-flow: column;
  align-items: center;
  flex: 1;
  overflow: scroll;
}

.store__product_list_item {
  align-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-radius: 21px;

  &:hover {
    background-color: #e2e6ec;
  }

  &--active {
    font-weight: bold;
    background-color: #cfd3da;

    &:hover {
      background-color: #cfd3da;
    }
  }
}

.store__product_form_container {
  flex: 5;
  display: flex;
  flex-flow: row;
  overflow: scroll;
}

.store__product_form {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  flex: 3;
  align-content: flex-start;
}

.product_form__input {
  flex: 0 1 25%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.product_price_row {
  flex: 1;
  display: flex;
}

.product_price {
  width: 100%;
  background-color: white;
  height: 20rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border-radius: 21px;
}

.checkout_modal_container {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.checkout_modal {
  background-color: #fefefe;
  margin: 2.5% auto;
  padding: 1.5rem;
  border: 1px solid #888;
  width: 90%;
  max-width: 850px;
  min-height: 50%;
  border-radius: 11px;
}

.checkout_modal {
  .checkout_modal__header,
  .checkout_modal__footer,
  form {
    scale: 0.9;
  }

  form {
    margin: -25px 0;
    display: flex; // prevent marging collapse
    flex-direction: column;

    label.font-weight-bold {
      font-weight: normal !important;
    }
  }
}

.cart__prices_prices {
  text-align: right;
}

.cart__prices_labels {
  text-align: left;
}

.cart__prices {
  border-top: 0.5px #c0bdbd solid;
  padding-top: 0.8rem;
  padding-bottom: 0.2rem;
  display: flex;
  justify-content: space-between;
}
